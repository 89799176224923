import React from "react";
import Hero from "./components/Hero.js";
import Projects from "./components/Projects";
import Contact from "./components/Contact";

const App = () => {
  return (
    <div style={styles.App}>
      <Hero />
      <Projects />
      <Contact />
    </div>
  );
};

const styles = {
  App: {
    backgroundColor: "#ffffff",
  },
};

export default App;
