import React from "react";
import "../App.css";

const ProjectLinks = ({ links }) => {
  const linkElements = [];
  links.forEach((link, index) => {
    linkElements.push(
      <li key={index} style={styles.listItemStyle}>
        <a href={link[1]} target="_blank" rel="noopener noreferrer">
          {link[0]}
        </a>
      </li>
    );
  });
  return (
    <div>
      <ul style={styles.list}>
        <b>Links:</b>
        {linkElements}
      </ul>
    </div>
  );
};

const styles = {
  listItemStyle: {
    marginLeft: "10px",
  },
  list: {
    listStyleType: "square",
  },
};

export default ProjectLinks;
