import React from "react";
import "../App.css";

const ProjectScreenshots = ({ screenshots }) => {
  const screenshotElements = [];
  screenshots.forEach((screenshot, index) => {
    screenshotElements.push(
      <div className="zoom" key={index}>
        <a href={screenshot}>
          <img
            style={styles.screenshot}
            src={screenshot}
            alt={"Screenshot " + index}
          />
        </a>
      </div>
    );
  });
  return (
    <div>
      <h2 style={styles.title}>Screenshots</h2>
      <div style={styles.container}>{screenshotElements}</div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  screenshot: {
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    borderColor: "#615055",
    borderWidth: "2px",
    borderStyle: "solid",
  },
  title: {
    color: "#000000",
    textAlign: "center",
    textDecoration: "underline",
    textDecorationColor: "#98ffcc",
  },
};

export default ProjectScreenshots;
