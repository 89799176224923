import React from "react";
import "../App.css";

const InfoSection = ({ title, text }) => {
  return (
    <div style={styles.infoContainer}>
      <h2 style={styles.subTitle}>{title}</h2>
      <p style={styles.infoText}>{text}</p>
    </div>
  );
};

const styles = {
  infoContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "column",
    flexWrap: "wrap",
    backgroundColor: "#ffffff",
    alignSelf: "center",
    margin: "20px",
  },
  subTitle: {
    color: "#000000",
    textAlign: "center",
    textDecoration: "underline",
    textDecorationColor: "#98ffcc",
    margin: "1px",
  },
  infoText: {
    textAlign: "center",
  },
};

export default InfoSection;
