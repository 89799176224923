import React from "react";
import ProjectShowcase from "./ProjectShowcase";
import Pulse from "react-reveal/Pulse";
const scImg = require("../images/scImg.png");
const scScreenshots = [
  require("../images/scScreen1.png"),
  require("../images/scScreen2.png"),
  require("../images/scScreen3.png"),
]
const pcImg = require("../images/pcImg.webp");
const pcScreenshots = [
  require("../images/pcScreen1.webp"),
  require("../images/pcScreen2.webp"),
  require("../images/pcScreen3.webp"),
];
const drinkUpImg = require("../images/drinkUpImg.webp");
const drinkUpScreenshots = [
  require("../images/drinkUpScreen1.webp"),
  require("../images/drinkUpScreen2.webp"),
  require("../images/drinkUpScreen3.webp"),
];
const cpsImg = require("../images/cpsImg.png");
const cpsScreenshots = [
  require("../images/cpsScreen1.png"),
  require("../images/cpsScreen2.png"),
  require("../images/cpsScreen3.png"),
];
const mushroomologyImg = require("../images/mushroomologyImg.png");
const mushroomologyScreenshots = [
  require("../images/mushroomologyScreen1.jpg"),
  require("../images/mushroomologyScreen2.jpg"),
  require("../images/mushroomologyScreen3.jpg"),
];
const problemonImg = require("../images/problemonImg.png");
const problemonScreenshots = [
  require("../images/problemonScreen1.jpg"),
  require("../images/problemonScreen2.jpg"),
  require("../images/problemonScreen3.jpg"),
];

const Projects = () => {
  return (
    <div>
      <Pulse>
        <h1 style={styles.title}>Interesting Projects</h1>
      </Pulse>
      <ProjectShowcase
        title="sick.cash"
        icon={scImg}
        desc="My thesis project, an online multiplayer board game akin to Monopoly. My responsibilties included the front-end design and implementation, as well as implementing certain basic back-end functionalities."
        date="October 2021 - Ongoing"
        type="Web App"
        techs="React, Typescript, .NET Core, SQL Server, C#, Azure"
        links={[
          ["Sick Cash", "https://www.sick.cash"]
        ]}
        screenshots={scScreenshots}
      />
      <hr />
      <ProjectShowcase
        title="Phasmophobia Companion"
        icon={pcImg}
        desc="A companion app to the popular horror game Phasmophobia. Over 17,000 downloads, available in many different languages."
        date="October 2020"
        type="Native Application"
        techs="React Native, Reanimated"
        links={[
          ["Google Play Store", "https://play.google.com/store/apps/details?id=com.phasmophobiacompanion&hl=en&gl=US"],
          ["Source", "https://github.com/Redseb/phasmophobia-companion"],
          ["DevTo Write-up", "https://dev.to/redseb/how-i-made-a-phasmophobia-companion-app-in-3-days-bl0"]
        ]}
        screenshots={pcScreenshots}
      />
      <hr />
      <ProjectShowcase
        title="Drink Up!"
        icon={drinkUpImg}
        desc="A fun drinking game with beautiful animations that run smoothly even on low-grade android devices."
        date="October 2020"
        type="Native Application"
        techs="React Native, Reanimated"
        links={[
          ["Google Play Store", "https://play.google.com/store/apps/details?id=com.drink.up&gl=PL"],
          ["Source", "https://github.com/Redseb/DrinkUp"],
        ]}
        screenshots={drinkUpScreenshots}
      />
      <hr />
      <ProjectShowcase
        title="Culture Power Solutions"
        icon={cpsImg}
        desc="A website for hosting text, video, and photo content for my father's company: Culture Power Solutions"
        date="January 2020"
        type="Website"
        techs="React, React-Router"
        links={[
          ["Live", "https://www.zyzanski.com/"],
          ["Source", "https://github.com/Redseb/Culture-Power-Solutions-site"],
        ]}
        screenshots={cpsScreenshots}
      />
      <hr />

      <ProjectShowcase
        title="Mushroomology"
        icon={mushroomologyImg}
        desc="An international multi-lingual Mushroom picking guide. Available on the Google Play Store with over 4,000 downloads in both an ad and ad-free version."
        date="January 2020"
        type="Native Application"
        techs="React Native, Expo"
        links={[
          [
            "Google Play Store",
            "https://play.google.com/store/apps/details?id=com.mikolajzyzanski.mushroomology",
          ],
        ]}
        screenshots={mushroomologyScreenshots}
      />
      <hr />

      <ProjectShowcase
        title="Problemon"
        icon={problemonImg}
        desc="A turned based calculus game! Built within 24 hours during a self-organized Game Jam together with 2 other team members. I was largely responsible for the front-end. Available as a PWA and as a native application"
        date="February 2020"
        type="PWA / Native Application"
        techs="React Native, Expo, Expo Web"
        links={[
          [
            "Google Play Store",
            "https://play.google.com/store/apps/details?id=problemon.mma.pjatk",
          ],
          ["PWA", "https://redseb.github.io/Problemon/"],
          ["Source", "https://github.com/Redseb/Problemon"],
        ]}
        screenshots={problemonScreenshots}
      />
      <hr />
    </div>
  );
};

const styles = {
  title: {
    color: "#000000",
    textAlign: "center",
    textDecoration: "underline",
    textDecorationColor: "#98ffcc",
    margin: 0,
  },
};

export default Projects;