import React from "react";
import "../App.css";
import {
  IoLogoTwitter,
  IoMdMail,
  IoLogoGithub,
  IoMdCall,
  IoMdDocument,
} from "react-icons/io";
import HeadShake from "react-reveal/HeadShake";
const cv = require("../documents/MikołajZyzańskiCV.pdf");

const Contact = () => {
  return (
    <div style={styles.outContainer}>
      <HeadShake>
        <h1 style={styles.title}>Sound interesting?</h1>
      </HeadShake>
      <div style={styles.container}>
        <div style={styles.contactInfo}>
          <b>
            <a href={cv} download>
              <IoMdDocument /> Download CV
            </a>
          </b>
        </div>
        <div style={styles.contactInfo}>
          <IoMdCall /> +48 517 826 284
        </div>
        <div style={styles.contactInfo}>
          <IoMdMail />
          <a href="mailto: mikolajzyzanski@gmail.com">
            mikolajzyzanski@gmail.com
          </a>
        </div>
        <div style={styles.contactInfo}>
          <IoLogoGithub /> <a href="github.com/Redseb">github.com/Redseb</a>
        </div>
        <div style={styles.contactInfo}>
          <IoLogoTwitter />
          <a href="https://twitter.com/mikoZyzanski">@mikoZyzanski</a>
        </div>
      </div>
    </div>
  );
};

const styles = {
  outContainer: {
    backgroundColor: "#FFFFFF",
  },
  container: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: "50px",
  },
  title: {
    color: "#000000",
    textAlign: "center",
    textDecoration: "underline",
    textDecorationColor: "#98ffcc",
  },
  contactInfo: {
    paddingBottom: "10px",
  },
};

export default Contact;
