import React from "react";
import "../App.css";
import Pulse from "react-reveal/Pulse";

const WorkShowcase = ({
  title,
  icon,
  desc,
  date,
  role,
  techs,
  links,
}) => {
  return (
    <div>
      <div style={styles.container}>
        <div style={styles.highlightContainer}>
          <a href={links[0][1]} target="_blank" rel="noopener noreferrer">
            <Pulse bottom>
              <img style={styles.icon} src={icon} alt={title + " Icon"} />
            </Pulse>
          </a>
        </div>
        <div style={styles.descContainer}>
          <a href={links[0][1]} target="_blank" rel="noopener noreferrer">
            <h2 style={styles.title}>{title}</h2>
          </a>
          <p>
            <b>{date}</b>
          </p>
          <p>{desc}</p>

          <ul>
            <li style={styles.listItemStyle}>
              <b>Role:</b> {role}
            </li>
            <li style={styles.listItemStyle}>
              <b>Technologies:</b> {techs}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    backgroundColor: "#ffffff",
    flexDirection: "row",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  highlightContainer: {},
  descContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "50vh",
    margin: "10px",
  },
  icon: {
    width: "30vh",
    height: "30vh",
    borderRadius: "10px",
    borderColor: "#615055",
    borderStyle: "solid",
  },
  title: {
    color: "#000000",
    textAlign: "center",
    textDecoration: "underline",
    textDecorationColor: "#98ffcc",
  },
  listItemStyle: {
    marginLeft: "10px",
  },
};

export default WorkShowcase;
